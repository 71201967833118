.CustomSelect {
  width: 100%;
  position: relative;
  display: block;
}

.CustomSelect__control {
  display: none;
}

.CustomSelect__empty {
  padding: 12px 0;
}

.CustomSelect__fetching {
  display: flex;
  align-items: center;
  justify-content: center;
}

.CustomSelect__empty {
  text-align: center;
  color: var(--text_secondary);
}
