.ButtonGroup {
  display: inline-flex;
  align-items: flex-start; /* Чтобы блоки по-умолчанию не растягивались на всю ширину контейнера */
}

.ButtonGroup--stretched {
  display: flex;
  width: 100%;
}

.ButtonGroup--mode-vertical {
  flex-direction: column;
}

.ButtonGroup--mode-horizontal {
  flex-direction: row;
}

/* stylelint-disable vkui/atomic */

/**
 * # Обоснование
 * Чтобы не завязываться на класс компонента, используем каскад и универсальный селектор.
 */

/* "gap" для mode="vertical" */
.ButtonGroup--mode-vertical.ButtonGroup--gap-space > *:not(:first-child) {
  margin-top: var(--vkui--size_button_group_gap_space--regular);
}

.ButtonGroup--mode-vertical.ButtonGroup--gap-s > *:not(:first-child) {
  margin-top: var(--vkui--size_button_group_gap_small--regular);
}

.ButtonGroup--mode-vertical.ButtonGroup--gap-m > *:not(:first-child) {
  margin-top: var(--vkui--size_button_group_gap_medium--regular);
}

/* "gap" для mode="horizontal" */
.ButtonGroup--mode-horizontal.ButtonGroup--gap-space > *:not(:last-child) {
  margin-right: var(--vkui--size_button_group_gap_space--regular);
}

.ButtonGroup--mode-horizontal.ButtonGroup--gap-s > *:not(:last-child) {
  margin-right: var(--vkui--size_button_group_gap_small--regular);
}

.ButtonGroup--mode-horizontal.ButtonGroup--gap-m > *:not(:last-child) {
  margin-right: var(--vkui--size_button_group_gap_medium--regular);
}

/* stylelint-enable vkui/atomic */
