.CalendarHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 36px;
}

.CalendarHeader__pickers {
  display: flex;
  position: absolute;
}

.CalendarHeader__pickers > :not(:last-child) {
  margin-right: 4px;
}

.CalendarHeader__nav-icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  color: var(--vkui--color_icon_accent);
}

.CalendarHeader__nav-icon-next {
  right: 0;
}

.CalendarHeader__nav-icon-prev {
  left: 0;
}

.CalendarHeader__picker-icon {
  margin-top: -4px;
  color: var(--vkui--color_icon_secondary);
}

.CalendarHeader__month_name {
  text-transform: capitalize;
}

.CalendarHeader__pickers-placeholder {
  text-transform: capitalize;
}

.CalendarHeader__pickers .CustomSelectDropdown__CustomScrollView {
  max-height: 184px;
}
