.InputLike__mask {
  position: relative;
}

.InputLike__mask::after {
  content: "";
  position: absolute;
  width: calc(100% - 0.1em);
  height: 1px;
  bottom: 0;
  left: 0;
  border-bottom: solid 1px var(--vkui--color_text_secondary);
}

.InputLike__mask:last-child {
  margin-right: 1px;
}

.InputLike__last_character {
  letter-spacing: 1px;
}

.InputLike--full + .InputLikeDivider {
  color: var(--vkui--color_text_primary);
}
