.Button {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  text-decoration: none;
  border: none;
  min-height: var(--vkui--size_button_small_height--compact);
  margin: 0;
  padding: 0;
  user-select: none;
  border-radius: var(--vkui--size_border_radius--regular);
  max-width: 100%;
}

.Button--stretched {
  display: block;
  width: 100%;
  flex-grow: 1;
  flex-basis: 0;
}

.Button__in {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: inherit;
  padding: 1px 16px;
  text-align: center;
  box-sizing: border-box;
}

.Button--aln-left .Button__in {
  justify-content: flex-start;
  text-align: left;
}

.Button--aln-right .Button__in {
  justify-content: flex-end;
  text-align: right;
}

.Button[disabled] {
  opacity: var(--vkui--opacity_disable);
}

.Button--lvl-primary[disabled]:not(.Button--clr-overlay):not(.Button--clr-negative):not(.Button--clr-positive),
.Button--lvl-secondary[disabled]:not(.Button--clr-overlay),
.Button--lvl-tertiary[disabled]:not(.Button--clr-overlay),
.Button--lvl-outline[disabled]:not(.Button--clr-overlay) {
  opacity: 0.64;
}

/* only 1 icon cases */
/* sizeY: compact */
.Button--singleIcon.Button--sz-s .Button__in {
  padding: 0 9px;
}

.Button--singleIcon.Button--sz-m .Button__in {
  padding: 0 10px;
}

.Button--singleIcon.Button--sz-l .Button__in {
  padding: 0 6px;
}

/* sizeX: REGULAR */
.Button--singleIcon.Button--sizeY-regular.Button--sz-s .Button__in {
  padding: 0 9px;
}

.Button--singleIcon.Button--sizeY-regular.Button--sz-l .Button__in {
  padding: 0 10px;
}

.Button--singleIcon .Button__before:only-child,
.Button--singleIcon .Button__after:only-child {
  margin: 0;
}

.Button__content {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Button__content--caps {
  transform: translateY(0);
}

.Button__spinner {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.Button__spinner + .Button__in {
  visibility: hidden;
}

/* Button's backgrounds */
/* Mode  = Primary */
.Button--lvl-primary.Button--clr-accent {
  background-color: var(
    --button_primary_background,
    var(--vkui--color_background_accent)
  );
}

.Button--lvl-primary.Button--clr-accent.Button--hover {
  background-color: var(--vkui--color_background_accent--hover);
}

.Button--lvl-primary.Button--clr-accent.Button--active {
  background-color: var(--vkui--color_background_accent--active);
}

.Button--lvl-primary.Button--clr-positive {
  background-color: var(
    --button_commerce_background,
    var(--vkui--color_background_positive)
  );
}

.Button--lvl-primary.Button--clr-positive.Button--hover {
  background-color: var(--vkui--color_background_positive--hover);
}

.Button--lvl-primary.Button--clr-positive.Button--active {
  background-color: var(--vkui--color_background_positive--active);
}

.Button--lvl-primary.Button--clr-negative {
  background-color: var(--destructive, var(--vkui--color_background_negative));
}

.Button--lvl-primary.Button--clr-negative.Button--hover {
  background-color: var(--vkui--color_background_negative--hover);
}

.Button--lvl-primary.Button--clr-negative.Button--active {
  background-color: var(--vkui--color_background_negative--active);
}

.Button--lvl-primary.Button--clr-neutral {
  background-color: var(
    --content_tint_background,
    var(--vkui--color_background_secondary)
  );
}

.Button--lvl-primary.Button--clr-neutral.Button--hover {
  background-color: var(--vkui--color_background_secondary--hover);
}

.Button--lvl-primary.Button--clr-neutral.Button--active {
  background-color: var(--vkui--color_background_secondary--active);
}

/* Mode = Secondary */
.Button--lvl-secondary {
  background-color: var(
    --button_secondary_background,
    var(--vkui--color_background_secondary)
  );
}

.Button--lvl-secondary.Button--hover {
  background-color: var(--vkui--color_background_secondary--hover);
}

.Button--lvl-secondary.Button--active {
  background-color: var(--vkui--color_background_secondary--active);
}

/* Mode = Tertiary */
.Button--lvl-tertiary,
.Button--lvl-outline {
  background-color: var(
    --button_tertiary_background,
    var(--vkui--color_transparent)
  );
}

.Button--lvl-tertiary.Button--hover,
.Button--lvl-outline.Button--hover {
  background-color: var(--vkui--color_transparent--hover);
}

.Button--lvl-tertiary.Button--active,
.Button--lvl-outline.Button--active {
  background-color: var(--vkui--color_transparent--active);
}

/*
  Buttons text colors
*/
.Button--lvl-primary.Button--clr-accent {
  color: var(--button_primary_foreground, var(--vkui--color_text_contrast));
}

.Button--lvl-primary.Button--clr-negative {
  color: var(--white, var(--vkui--color_text_contrast));
}

.Button--lvl-primary.Button--clr-positive {
  color: var(--button_commerce_foreground, var(--vkui--color_text_contrast));
}

.Button--lvl-primary.Button--clr-accent.Button--hover,
.Button--lvl-primary.Button--clr-positive.Button--hover,
.Button--lvl-primary.Button--clr-negative.Button--hover {
  color: var(--vkui--color_text_contrast--hover);
}

.Button--lvl-primary.Button--clr-accent.Button--active,
.Button--lvl-primary.Button--clr-positive.Button--active,
.Button--lvl-primary.Button--clr-negative.Button--active {
  color: var(--vkui--color_text_contrast--active);
}

/*
  Text colors of background-colored buttons
*/
.Button--lvl-tertiary.Button--clr-accent {
  color: var(--button_tertiary_foreground, var(--vkui--color_text_accent));
}

.Button--lvl-secondary.Button--clr-accent {
  color: var(--button_secondary_foreground, var(--vkui--color_text_accent));
}

.Button--lvl-outline.Button--clr-accent {
  color: var(--button_outline_foreground, var(--vkui--color_text_accent));
}

.Button--lvl-tertiary.Button--clr-accent.Button--hover,
.Button--lvl-secondary.Button--clr-accent.Button--hover,
.Button--lvl-outline.Button--clr-accent.Button--hover {
  color: var(--vkui--color_text_accent--hover);
}

.Button--lvl-tertiary.Button--clr-accent.Button--active,
.Button--lvl-secondary.Button--clr-accent.Button--active,
.Button--lvl-outline.Button--clr-accent.Button--active {
  color: var(--vkui--color_text_accent--active);
}

.Button--lvl-tertiary.Button--clr-positive,
.Button--lvl-secondary.Button--clr-positive,
.Button--lvl-outline.Button--clr-positive {
  color: var(--button_commerce_background, var(--vkui--color_text_positive));
}

.Button--lvl-tertiary.Button--clr-positive.Button--hover,
.Button--lvl-secondary.Button--clr-positive.Button--hover,
.Button--lvl-outline.Button--clr-positive.Button--hover {
  color: var(--vkui--color_text_positive--hover);
}

.Button--lvl-tertiary.Button--clr-positive.Button--active,
.Button--lvl-secondary.Button--clr-positive.Button--active,
.Button--lvl-outline.Button--clr-positive.Button--active {
  color: var(--vkui--color_text_positive--active);
}

.Button--lvl-tertiary.Button--clr-negative,
.Button--lvl-secondary.Button--clr-negative,
.Button--lvl-outline.Button--clr-negative {
  color: var(--destructive, var(--vkui--color_text_negative));
}

.Button--lvl-tertiary.Button--clr-negative.Button--hover,
.Button--lvl-secondary.Button--clr-negative.Button--hover,
.Button--lvl-outline.Button--clr-negative.Button--hover {
  color: var(--vkui--color_text_negative--hover);
}

.Button--lvl-tertiary.Button--clr-negative.Button--active,
.Button--lvl-secondary.Button--clr-negative.Button--active,
.Button--lvl-outline.Button--clr-negative.Button--active {
  color: var(--vkui--color_text_negative--active);
}

.Button--lvl-primary.Button--clr-neutral,
.Button--lvl-secondary.Button--clr-neutral,
.Button--lvl-tertiary.Button--clr-neutral,
.Button--lvl-outline.Button--clr-neutral {
  color: var(--text_primary, var(--vkui--color_text_primary));
}

.Button--lvl-primary.Button--clr-neutral.Button--hover,
.Button--lvl-secondary.Button--clr-neutral.Button--hover,
.Button--lvl-tertiary.Button--clr-neutral.Button--hover,
.Button--lvl-outline.Button--clr-neutral.Button--hover {
  color: var(--vkui--color_text_primary--hover);
}

.Button--lvl-primary.Button--clr-neutral.Button--active,
.Button--lvl-secondary.Button--clr-neutral.Button--active,
.Button--lvl-tertiary.Button--clr-neutral.Button--active,
.Button--lvl-outline.Button--clr-neutral.Button--active {
  color: var(--vkui--color_text_primary--active);
}

/*
  Outline buttons borders
*/
.Button--lvl-outline.Button--clr-accent {
  box-shadow: inset 0 0 0 1px
    var(--button_outline_border, var(--vkui--color_stroke_accent));
}

.Button--lvl-outline.Button--clr-accent.Button--hover {
  box-shadow: inset 0 0 0 1px var(--vkui--color_stroke_accent--hover);
}

.Button--lvl-outline.Button--clr-accent.Button--active {
  box-shadow: inset 0 0 0 1px var(--vkui--color_stroke_accent--active);
}

.Button--lvl-outline.Button--clr-positive {
  box-shadow: inset 0 0 0 1px
    var(--button_commerce_background, var(--vkui--color_stroke_positive));
}

.Button--lvl-outline.Button--clr-positive.Button--hover {
  box-shadow: inset 0 0 0 1px var(--vkui--color_stroke_positive--hover);
}

.Button--lvl-outline.Button--clr-positive.Button--active {
  box-shadow: inset 0 0 0 1px var(--vkui--color_stroke_positive--active);
}

.Button--lvl-outline.Button--clr-negative {
  box-shadow: inset 0 0 0 1px
    var(--destructive, var(--vkui--color_stroke_negative));
}

.Button--lvl-outline.Button--clr-negative.Button--hover {
  box-shadow: inset 0 0 0 1px var(--vkui--color_stroke_negative--hover);
}

.Button--lvl-outline.Button--clr-negative.Button--active {
  box-shadow: inset 0 0 0 1px var(--vkui--color_stroke_negative--active);
}

.Button--lvl-outline.Button--clr-neutral {
  box-shadow: inset 0 0 0 1px
    var(--field_border, var(--vkui--color_field_border_alpha));
}

.Button--lvl-outline.Button--clr-neutral.Button--hover {
  background-color: var(--vkui--color_background_secondary--hover);
  box-shadow: unset;
}

.Button--lvl-outline.Button--clr-neutral.Button--active {
  background-color: var(--vkui--color_background_secondary--active);
  box-shadow: unset;
}

/*
  Overlay colors section, separately
  Background colors
*/
.Button--lvl-primary.Button--clr-overlay {
  background-color: var(
    --media_overlay_button_background,
    var(--vkui--color_background_contrast)
  );
}

.Button--lvl-primary.Button--clr-overlay.Button--hover {
  background-color: var(--vkui--color_background_contrast--hover);
}

.Button--lvl-primary.Button--clr-overlay.Button--active {
  background-color: var(--vkui--color_background_contrast--active);
}

.Button--lvl-secondary.Button--clr-overlay {
  background-color: var(
    --vkui--color_background_contrast_secondary_alpha,
    rgba(255, 255, 255, 0.2)
  );
}

.Button--lvl-secondary.Button--clr-overlay.Button--hover,
.Button--lvl-tertiary.Button--clr-overlay.Button--hover,
.Button--lvl-outline.Button--clr-overlay.Button--hover {
  background-color: var(
    --vkui--color_background_contrast_secondary_alpha--hover
  );
}

.Button--lvl-secondary.Button--clr-overlay.Button--active,
.Button--lvl-tertiary.Button--clr-overlay.Button--active,
.Button--lvl-outline.Button--clr-overlay.Button--active {
  background-color: var(
    --vkui--color_background_contrast_secondary_alpha--active
  );
}

/* Text colors */
.Button--lvl-primary.Button--clr-overlay {
  color: var(
    --media_overlay_button_foreground,
    var(--vkui--color_text_primary_invariably)
  );
}

.Button--lvl-primary.Button--clr-overlay.Button--hover {
  color: var(--vkui--color_text_primary_invariably--hover);
}

.Button--lvl-primary.Button--clr-overlay.Button--active {
  color: var(--vkui--color_text_primary_invariably--active);
}

.Button--lvl-secondary.Button--clr-overlay,
.Button--lvl-tertiary.Button--clr-overlay,
.Button--lvl-outline.Button--clr-overlay {
  color: var(--white, var(--vkui--color_text_contrast));
}

.Button--lvl-secondary.Button--clr-overlay.Button--hover,
.Button--lvl-tertiary.Button--clr-overlay.Button--hover,
.Button--lvl-outline.Button--clr-overlay.Button--hover {
  color: var(--vkui--color_text_contrast--hover);
}

.Button--lvl-secondary.Button--clr-overlay.Button--active,
.Button--lvl-tertiary.Button--clr-overlay.Button--active,
.Button--lvl-outline.Button--clr-overlay.Button--active {
  color: var(--vkui--color_text_contrast--active);
}

/* Borders */
.Button--lvl-outline.Button--clr-overlay {
  box-shadow: inset 0 0 0 1px var(--white, var(--vkui--color_stroke_contrast));
}

.Button--lvl-outline.Button--clr-overlay.Button--hover {
  box-shadow: inset 0 0 0 1px var(--vkui--color_stroke_contrast--hover);
}

.Button--lvl-outline.Button--clr-overlay.Button--active {
  box-shadow: inset 0 0 0 1px var(--vkui--color_stroke_contrast--active);
}

.Button__before:not(:last-child) {
  margin-left: -4px;
}

.Button__before:not(:last-child) + .Button__content {
  margin-left: 6px;
}

.Button__after:not(:first-child) {
  margin-left: 6px;
}

.Button--sz-l .Button__before:not(:last-child) + .Button__content {
  margin-left: 8px;
}

.Button--sz-l .Button__after:not(:first-child) {
  margin-left: 8px;
}

.Button--sz-l .Button__in {
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
}

.Button:not(.Button--sz-s) .Button__content {
  transform: translateY(-1px);
}

/**
 * sizeY COMPACT
 */
.Button--sz-m {
  min-height: var(--vkui--size_button_medium_height--compact);
}

.Button--sz-l {
  min-height: var(--vkui--size_button_large_height--compact);
}

/**
 * sizeY REGULAR
 */
.Button--sizeY-regular {
  min-height: var(--vkui--size_button_small_height--regular);
}

.Button--sizeY-regular.Button--sz-m {
  min-height: var(--vkui--size_button_medium_height--regular);
}

.Button--sizeY-regular.Button--sz-l {
  min-height: var(--vkui--size_button_large_height--regular);
}

/**
 * CMP:
 * Banner
 *
 * ====================
 *
 * FIXME Следует поправить компонент на уровне уровне дизайн-системы
 *  Сейчас нет возможности использовать `ButtonGroup`, т.к. эти стили конфликтуют с `ButtonGroup.css`:
 *  1. Есть привзяка к компоненту `.Button--lvl-tertiary`. Главная проблема, т.к. отступы в `ButtonGroup.css` не зависят от типа кнопки.
 *  2. `margin-top` следует выставить обёртке `.Banner__actions`. Нужно иметь ввиду, что значение отступа меняются в зависимости от типа кнопки (см. п. 1)
 *  2. `margin-right` добавляет лишний оступ,
 *  3. `6px` конфликтуют со значениями в `gap`,
 *  4. У обёртки `.Banner__actions` выставлен отрицительный внешний снизу в 6px.
 */
.Banner__actions > .Button {
  margin-top: 12px;
  margin-right: 16px;
  margin-bottom: 6px;
}

.Banner__actions > .Button--lvl-tertiary {
  margin: 4px 16px 2px -16px;
}

.Banner__actions > .Button--lvl-tertiary:first-child {
  margin-top: 4px;
}

.Banner__actions
  > .Button:not(.Button--lvl-tertiary)
  + .Button:not(.Button--lvl-tertiary) {
  margin-top: 0;
}

.Banner__actions > .Button:not(.Button--lvl-tertiary) + .Button--lvl-tertiary {
  margin-top: 2px;
  margin-left: -16px;
}

.Banner__actions > .Button--lvl-tertiary + .Button--lvl-tertiary {
  margin-top: 2px;
}

/**
 * CMP:
 * ModalCardBase
 *
 * ====================
 *
 * FIXME Следует поправить компонент на уровне уровне дизайн-системы
 *  Сейчас нет возможности использовать `ButtonGroup`, т.к. эти стили конфликтуют с `ButtonGroup.css`:
 *  1. `flex: 1` конфликтует с `stretched={false}`.
 *  2. `margin-right` добавляет лишний оступ,
 *  3. `6px` конфликтуют со значениями в `gap`,
 *  4. У обёртки `.ModalCardBase__actions` выставлены отрицительные внешние отступы слева/справа в 6px.
 */
.ModalCardBase__actions .Button {
  flex: 1;
  margin-left: 6px;
  margin-right: 6px;
}

.ModalCardBase__actions--v .Button {
  margin-left: 0;
  margin-right: 0;
}

.ModalCardBase__actions--v .Button + .Button {
  margin-top: 12px;
}

/**
 * CMP:
 * RichCell
 *
 * ====================
 *
 * @depracated Пользователям следует использовать `ButtonGroup` (см. пример `RichCell/Readme.md`).
 *
 * TODO Сейчас стиль сохранён для обртаной совместимости. Удалить в следующей мажорной версии.
 */
.RichCell__actions > .Button + .Button {
  margin-left: 8px;
}
